import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import _ from 'lodash';


const cast = (input) => {
  if (typeof input === 'string' && input.includes(':')) {
    return  input.split(',').reduce((previousValue, property) => {
      const [key, value] = property.split(':');

      return _.merge(previousValue, _.set({}, key, cast(value)))
    }, {});
  }

  if (input.toLowerCase() === 'true' || input.toLowerCase() === 'false') {
    return input.toLowerCase() === 'true';
  }

  // Try to cast to number
  const parsedNumber = parseFloat(input);
  if (!isNaN(parsedNumber)) {
    return parsedNumber;
  }

  // If no casting is possible, return the original string
  return input;
}

/**
 *
 * @param element {HTMLElement}
 * @returns {Swiper}
 */
const buildSwiper = (element) => {
  const options = Object.fromEntries(
    Object.entries({ ...element.dataset }).reduce(
      (previousValue, [key, value]) => {
        if (key.includes('swiper')) {
          const optionKey = key.charAt(6).toLowerCase() + key.slice(7);
          return [
            ...previousValue,
            [
              optionKey,
              cast(value),
            ],
          ];
        }

        return previousValue;
      },
      []
    )
  );

  console.log(element.dataset, options);

  const swiper = new Swiper(element, {
    slidesPerView: 3,
    spaceBetween: 20,
    ...options,
    modules: [Pagination],
    pagination: {
      el: `#${element.id} > .swiper-pagination`,
      type: 'bullets',
      clickable: true,
    },
  });

  return swiper;
};

const sliders = document.querySelectorAll('.swiper');
sliders.forEach((slider) => buildSwiper(slider));
